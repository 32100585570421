import React from "react"

import servicesStyles from "./services.module.css"

import webappImage from "../images/web-app.svg"
import customImage from "../images/custom.svg"
import wordpressImage from "../images/wordpress.svg"

export default () => {
  return (  
    <div className={servicesStyles.container}>
      <div className={servicesStyles.services}>
        <div className={servicesStyles.service}>
          <h3>
            Custom development
          </h3>
          <img src={customImage} alt="Custom" className={servicesStyles.icon}/>
          <div>
            <p>
              Have an idea and need someone to build it? 
            </p>
            <p>
              With my experience as a corporate tech consultant, I've been actively involved in all phases of a project,
              from blueprinting, to architecture, go-live and support.
            </p>
          </div>
        </div>
        <div className={servicesStyles.service}>
          <h3>
            Web Development
          </h3>
          <img src={webappImage} alt="Web App" className={servicesStyles.icon}/>
          <div>
            <p>
              Need a web application that's <strong>fast</strong>, <strong>responsive</strong> and ready for
              <strong> desktop</strong> and <strong>mobile</strong>?
            </p>
            <p>
              Using the latest available technologies like React, GatsbyJS and Node.js, let me help you get there.
            </p>
          </div>
        </div>
        <div className={servicesStyles.service}>
          <h3>
            WordPress on Overdrive
          </h3>
          <img src={wordpressImage} alt="Wordpress" className={servicesStyles.icon}/>
          <div>
            <p>
              Keep using WordPress to create your content, but give your site a <strong>fresh</strong> and <strong>unique</strong> look.
            </p>
            <p>
              Let me move your WordPress site over to the headless CMS paradigm, to give you more freedom on what
              technologies to use, ensuring you are always at the leading edge.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
