import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Services from "../components/services"

export default () => (
  <Layout>
    <SEO title="Projects" />
    <div>
      <h1>Projects</h1>
      <p>
        Coming soon...
      </p>
      <Services />
    </div>
  </Layout>
)